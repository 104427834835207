import React from 'react';
import { Typography, TypographyVariant } from 'components/core';
import { NotificationContainer } from 'modules/2023-q3/banking-page/v2/components/bank-accounts/components/bank/components/bank-plaid-error/components/notification-container/notification-container';

export const AttentionNotification = () => {
  return (
    <NotificationContainer display="inline-block" position="relative">
      <Typography color="grey1" variant={TypographyVariant.Header4}>
        We&apos;re sunsetting Treasure Cash, please choose an option below
      </Typography>
    </NotificationContainer>
  );
};

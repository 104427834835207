import React from 'react';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import { Deposit } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/deposit/deposit';
import { CheckAccountStatusBeforeDeposit } from 'modules/2024-q3/grasshopper-sunset/deposit/check-account-status-before-deposit/check-account-status-before-deposit';
import { Deposit as DepositApexACH } from 'modules/2024-q4/apex-ach/deposit-apex-ach/deposit';

export const DepositTransition = () => {
  // testing Apex ACH flow
  // TODO: remove me
  const { isFeatureFlagEnabled } = useFeatureFlags();

  if (isFeatureFlagEnabled(FeatureFlags.REACT_APP_2024_Q4_APEX_ACH)) {
    return <DepositApexACH />;
  }

  return (
    <FeatureFlag
      disabled={<Deposit />}
      enabled={<CheckAccountStatusBeforeDeposit />}
      flag={FeatureFlags.REACT_APP_2024_Q3_GRASSHOPPER_SUNSET}
    />
  );
};

import React, { useEffect } from 'react';
import { IHeaderButtons } from 'modules/dashboard/header/components/header-buttons/header-buttons';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useTracking, TrackEventName } from 'modules/tracking';
import { MenuOptions } from '../menu-options/menu-options';

interface MenuButtonProps {
  menuStatuses: IHeaderButtons;

  setMenuStatuses: React.Dispatch<React.SetStateAction<IHeaderButtons>>;
}

export const DepositWithdrawalButton: React.FC<MenuButtonProps> = ({
  menuStatuses,
  setMenuStatuses,
}) => {
  const { trackEvent } = useTracking();

  const history = useHistory();
  const location = useLocation();

  const buttonText = 'Deposit / Withdrawal';

  let timeout: ReturnType<typeof setTimeout>;

  const handleOnClick = () => {
    trackEvent({
      buttonText,
      eventName: TrackEventName.Click,
    });
  };

  const handleShowMenu = () => {
    setMenuStatuses({
      appMenuButton: false,
      depositWithdrawalButton: true,
    });

    clearTimeout(timeout);
  };

  const hideMenu = () => {
    setMenuStatuses((previousState) => ({
      ...previousState,
      depositWithdrawalButton: false,
    }));
  };

  const handleHideMenu = () => {
    timeout = setTimeout(() => {
      hideMenu();
    }, 500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [menuStatuses.depositWithdrawalButton]);

  const menuOptions = [
    {
      action: () => {
        history.push({
          pathname: RoutesPath.tasks.deposit.path,
          state: { from: location },
        });
      },
      description: 'Deposit',
      eventName: TrackEventName.Click,
      path: RoutesPath.tasks.deposit.path,
    },
    {
      action: () => {
        history.push({
          pathname: RoutesPath.tasks.withdrawal.path,
          state: { from: location },
        });
      },
      description: 'Withdrawal',
      eventName: TrackEventName.Click,
      path: RoutesPath.tasks.withdrawal.path,
    },
  ];

  return (
    <>
      {menuStatuses.depositWithdrawalButton && (
        <Box
          onMouseEnter={handleShowMenu}
          onMouseLeave={handleHideMenu}
          position="relative"
          zIndex={200}
        >
          <Box position="absolute" top={32} left={0}>
            <MenuOptions onClickAway={hideMenu} options={menuOptions} />
          </Box>
        </Box>
      )}

      <Button
        cursor="auto"
        onClick={handleOnClick}
        onMouseEnter={handleShowMenu}
        onMouseLeave={handleHideMenu}
        onMouseUp={handleShowMenu}
      >
        <Box px={2} py={1}>
          <Typography color="white" variant={TypographyVariant.Paragraph2}>
            {buttonText}
          </Typography>
        </Box>
      </Button>
    </>
  );
};

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { AttentionNotification } from './components/attention-notification/attention-notification';
import { SectionContainer, SectionTitle } from 'modules/dashboard/components';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useBusiness } from 'hooks/business/use-business';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';

export const GrasshopperSunsetOptions = () => {
  const { data: business } = useBusiness();
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);

  const amountAtGrasshopper = aggregateBalances?.liquidity.oneDay.amount;
  const pendingWithdrawals = aggregateBalances?.pending.withdrawals.amount;

  const amountToWithdraw = amountAtGrasshopper - pendingWithdrawals;

  /*if (amountToWithdraw <= 0) {
    return null;
  }*/

  return (
    <Box pt={10} mb={25}>
      <Box mb={5} textAlign="center">
        <AttentionNotification />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs>
          <SectionContainer>
            <Box mb={2}>
              <SectionTitle title="Withdraw" />
            </Box>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non
            arcu feugiat, elementum metus id, fringilla nulla. Nulla at luctus
            ligula, eget tempus sapien. Nunc at magna vitae nunc finibus
            malesuada vel nec enim. Vestibulum vitae viverra metus. Suspendisse
            consectetur feugiat risus, tempor feugiat lectus lobortis in. Nulla
            eget molestie neque, eu dapibus orci. Etiam id massa sed mi
            vestibulum lacinia porta a dolor. Etiam cursus ante quis urna
            rhoncus, eget accumsan ante viverra. Etiam sit amet orci dignissim
            nisl rutrum tristique. Pellentesque maximus tristique finibus.
            Aenean id orci vulputate, viverra felis nec, sodales dolor.
            Phasellus fringilla elit elit, eu sagittis nisl porttitor non. Sed
            placerat arcu et ligula condimentum, a venenatis dolor ultricies.
            <Box mt={3}>
              <Link to={RoutesPath.tasks.withdrawal.path}>
                <Button fullWidth>
                  <ButtonContent>
                    Withdraw{' '}
                    <Currency
                      number={Math.abs(amountToWithdraw)}
                      variant={CurrencyVariant.Full}
                    />
                  </ButtonContent>
                </Button>
              </Link>

              <Box mt={1}>
                <Typography variant={TypographyVariant.SmallText}>
                  Any residual funds added after this withdrawal, will be sent
                  to the last bank account of the last withdrawl.
                </Typography>
              </Box>
            </Box>
          </SectionContainer>
        </Grid>

        <Grid item xs>
          <SectionContainer>
            <Box mb={2}>
              <SectionTitle title="Allocate" />
            </Box>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non
            arcu feugiat, elementum metus id, fringilla nulla. Nulla at luctus
            ligula, eget tempus sapien. Nunc at magna vitae nunc finibus
            malesuada vel nec enim. Vestibulum vitae viverra metus. Suspendisse
            consectetur feugiat risus, tempor feugiat lectus lobortis in. Nulla
            eget molestie neque, eu dapibus orci. Etiam id massa sed mi
            vestibulum lacinia porta a dolor. Etiam cursus ante quis urna
            rhoncus, eget accumsan ante viverra. Etiam sit amet orci dignissim
            nisl rutrum tristique. Pellentesque maximus tristique finibus.
            Aenean id orci vulputate, viverra felis nec, sodales dolor.
            Phasellus fringilla elit elit, eu sagittis nisl porttitor non. Sed
            placerat arcu et ligula condimentum, a venenatis dolor ultricies.
            <Box mt={3}>
              <Link to={RoutesPath.tasks.allocation.path}>
                <Button fullWidth>
                  <ButtonContent>
                    Allocate{' '}
                    <Currency
                      number={Math.abs(amountToWithdraw)}
                      variant={CurrencyVariant.Full}
                    />
                  </ButtonContent>
                </Button>
              </Link>
            </Box>
          </SectionContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
